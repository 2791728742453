<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span class="title">涨粉数据</span>
          </div>
          <div>
            <el-row class="table-header">
              <el-col :span="18">
                <PlatformsRadioGroup :platformCode="searchCondition.platform_code"
                                     @choicePlatform="choicePlatform"></PlatformsRadioGroup>
              </el-col>
            </el-row>
            <!--          筛选条件-->
            <el-row>
              <el-col :span="20" style="margin-top: 10px">
                <div style="display: inline;">
                  <el-form ref="searchForm" :inline="true" :model="searchCondition" style="display: inline">
                    <el-form-item label="">
                      <el-radio-group v-model="checkedRadio" @change="radioChange">
                        <el-radio-button :label="option.value" v-for="(option,index) in dayRange" :key="index">{{
                            option.label
                          }}
                        </el-radio-button>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item>
                      <el-date-picker
                          v-model="ym"
                          type="month"
                          value-format="yyyy-MM"
                          placeholder="选择月份" style="width: 120px" @change="changeMonth">
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                      <el-date-picker
                          v-model="rangYm"
                          type="monthrange"
                          align="right"
                          unlink-panels
                          range-separator="至"
                          start-placeholder="开始月份"
                          end-placeholder="结束月份"
                          value-format="yyyy-MM"
                          :picker-options="pickerOptions" @change="changeMonths">
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item label="自定义时间：" label-width="100px">
                      <el-date-picker
                          v-model="searchCondition.time_value"
                          type="daterange"
                          align="right"
                          unlink-panels
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          value-format="yyyy-MM-dd" @change="changeTime">
                      </el-date-picker>
                    </el-form-item>
                  </el-form>
                </div>
              </el-col>
              <el-col :span="4">
                <div style="text-align: right">
                  <export-link ref="refExportLink" :lint-title="`导出表格`"
                               :can-export="userPermissions.indexOf('performance_export') !== -1"
                               @export="exportData"/>

                  <el-link :underline="true" type="info" class="share-rank"
                           v-if="userPermissions.indexOf('dashboard_trend_artist_share') !== -1"><i
                      class="el-icon-share"></i>分享榜单
                  </el-link>
                </div>
              </el-col>
            </el-row>

            <!--            表格数据-->
            <el-row>
              <el-col :span="24" style="padding-top: 5px">
                <div class="default-table">
                  <el-table
                      :data="tableData"
                      :row-class-name="rowClassName"
                      :summary-method="getSummaries"
                      show-summary
                      border
                      style="width: 100%">
                    <el-table-column
                        prop="time_day"
                        :label="`日期`"
                        width="140" align="center">
                    </el-table-column>
                    <template v-for="(column,index) in columns">
                      <el-table-column :prop="`${column.value}`" :key="index" :label="`${column.title}`"
                                       :min-width="`${column.width}`" align="right" header-align="center">
                        <template slot-scope="scope">
                        <span :class="`${column.className}`"
                              v-if="column.className==='money'">{{ moneyFormat(scope.row[column.value] || 0) }}
                        </span>
                          <span :class="`${column.className}`" v-else-if="column.className==='qty'">
                          {{ numberFormat(scope.row[column.value] || 0) }}</span>
                          <span :class="`${column.className}`" v-else>
                          {{ scope.row[column.value] || 0 }}
                        </span>
                        </template>
                      </el-table-column>
                    </template>
                  </el-table>
                </div>
                <tips :data="['粉丝单价 = 推广花费 / 推广涨粉数']"/>
              </el-col>
            </el-row>
            <!--            <el-divider/>-->
            <!--        图表数据-->
            <!--            <StatisticsTrendChart :title="`增粉数据`" :searchCondition="searchCondition"></StatisticsTrendChart>-->

            <el-divider/>
            <!--          涨粉月数据-->
            <TrendMonthData :search-condition="searchCondition" v-if="searchCondition.platform_code"/>
          </div>

        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ExportLink from '@/components/export/ExportLink'
import PlatformsRadioGroup from '@/pages/platform/components/PlatformsRadioGroup'
import StatisticsTrendChart from '@/pages/dashboard/trend/StatisticsTrendChart'
import TrendMonthData from '@/pages/dashboard/trend/TrendMonthData'

export default {
  name: 'TrendData',
  components: {
    ExportLink,
    PlatformsRadioGroup,
    TrendMonthData,
    StatisticsTrendChart
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  props: {
    artistId: {
      type: String,
      require: true
    },
    nickname: {
      type: String,
      default: ''
    }
  },
  watch: {
    artistId(val) {
      // this.getInfo()
      this.searchCondition.artist_id = val
    },
    searchCondition: {
      deep: true,
      handler(val) {
        // console.log('val',val)
        this.initData(val)
      }
    }
  },
  data() {
    return {
      artistInfo: { nickname: '--' },
      searchCondition: {
        platform_code: null,
        artist_id: null,
        time_value: []
      },
      tableData: [],
      columns: [
        { value: 'total_num', title: '总粉丝数', width: 100, className: 'qty' },
        { value: 'add_num', title: '新增粉丝数', width: 100, className: 'qty' },
        { value: 'content_add_num', title: '内容增粉数', width: 100, className: 'qty' },
        { value: 'promotion_add_num', title: '推广增粉数', width: 100, className: 'qty' },
        { value: 'promotion_cost', title: '涨粉投放', width: 100, className: 'money' },
        { value: 'price', title: '粉丝单价', width: 100, className: 'money' },
        { value: 'ad_repair', title: '广告维护', width: 100, className: 'money' },
        { value: 'live_preheat', title: '直播预热', width: 100, className: 'money' },
        { value: 'add_works_num', title: '新增作品数', width: 100, className: 'qty' },
        { value: 'total_works_num', title: '总作品数', width: 100, className: 'qty' },
      ],
      dayRange: [
        { label: '近7日', value: 7 },
        { label: '近30天', value: 30 }
        // { label: '近60天', value: 60 },
        // { label: '近90天', value: 90 },
      ],
      checkedRadio: 7,
      ym: null,
      rangYm: null,
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }]
      }

    }
  },
  methods: {
    moneyFormat(value) {
      return this.$utils.numberFormat(value, 2, '.', ',', 'round')
    },
    numberFormat(value) {
      return this.$utils.numberFormat(value, 0, '.', ',', 'round')
    },
    rowClassName({ row, rowIndex }) {
      // 把每一行的索引放进row.id
      row.index = rowIndex + 1
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      let price_index = null// 涨粉单价的索引
      let promotion_cost_index = null// 总推广花费的索引
      let promotion_add_num_index = null// 总推广增粉数的索引
      let ad_repair_index = null//广告维护的索引
      let live_preheat_index = null//直播预热的索引
      columns.forEach((column, index) => {
        if (index < 0) {
          return
        }
        if (index === 0) {
          sums[index] = '合计:'
          return
        }
        // console.log(`${index}）${column.property}`)
        if (['add_num', 'content_add_num', 'promotion_add_num', 'promotion_cost', 'add_works_num','ad_repair','live_preheat'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev * 1 + curr * 1
              } else {
                return prev
              }
            }, 0)
            sums[index] += ''
          } else {
            sums[index] = 'N/A'
          }

          // // 格式化数据
          switch (column.property) {
            case 'promotion_cost':
              promotion_cost_index = index
              // console.log(`推广花费合计：${total_promotion_cost}`)
              // sums[index] = '¥' + this.moneyFormat(sums[index])
              break
            case 'promotion_add_num':
              promotion_add_num_index = index
              // console.log(`推广增粉合计：${total_promotion_add_num}`)
              break
            case 'ad_repair':
              ad_repair_index = index
              break
            case 'live_preheat':
              live_preheat_index = index
              break
            default:
              sums[index] = this.numberFormat(sums[index])
              break
          }
        }

        //涨粉单价列索引（）
        if (column.property === 'price') {
          price_index = index
        }
      })

      // 涨粉单价=推广花费/推广涨粉数
      if (price_index !== null && promotion_add_num_index !== null && promotion_cost_index !== null) {
        sums[price_index] = sums[promotion_add_num_index] > 0 ? sums[promotion_cost_index] / sums[promotion_add_num_index] : ''
        //格式化
        sums[promotion_cost_index] = '¥' + this.moneyFormat(sums[promotion_cost_index])
        sums[price_index] = '¥' + this.moneyFormat(sums[price_index])
        sums[promotion_add_num_index] = this.numberFormat(sums[promotion_add_num_index])
        sums[ad_repair_index] = '¥' + this.moneyFormat(sums[ad_repair_index])
        sums[live_preheat_index] = '¥' + this.moneyFormat(sums[live_preheat_index])
      }
      return sums
    },
    choicePlatform(val) {
      // console.log('searchCondition', this.searchCondition)
      this.searchCondition.platform_code = val
      this.initData()
    },
    changeMonths() {
      this.ym = null
      this.checkedRadio = null
      let beginMonth = this.rangYm[0]
      let endMonth = this.rangYm[1]
      let ymArr = endMonth.split('-')
      let day = new Date(parseInt(ymArr[0]), parseInt(ymArr[1]), 0)
      let daycount = day.getDate()//最后一天
      this.searchCondition.time_value = [beginMonth + '-01', endMonth + '-' + daycount]

      this.initData()
    },
    getLastDays(days) {
      //几天前的时间
      let day1 = new Date()
      day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000 * days)
      return day1.getFullYear() + '-' + (day1.getMonth() + 1) + '-' + day1.getDate()
    },
    choiceTimeData(days) {
      //选择时间
      let beginTime = this.getLastDays(days)
      let endTime = this.getLastDays(1)
      this.searchCondition.time_value = [beginTime, endTime]
    },
    radioChange() {
      this.choiceTimeData(this.checkedRadio)
      this.initData()
    },
    changeMonth() {
      this.rangYm = null
      this.checkedRadio = null
      let ymArr = this.ym.split('-')
      let day = new Date(parseInt(ymArr[0]), parseInt(ymArr[1]), 0)
      let daycount = day.getDate()//最后一天
      this.searchCondition.time_value = [this.ym + '-01', this.ym + '-' + daycount]

      this.initData()
    },
    changeTime() {
      this.ym = null
      this.rangYm = null
      this.checkedRadio = null
      this.initData()
    },
    async getArtistTrendData() {
      let searchCondition = this.handleSearchCondition()
      if (searchCondition.artist_id && searchCondition.platform_code && searchCondition.start_time && searchCondition.end_time) {
        let { list } = await this.$api.getTrendDatumArtistData(searchCondition)
        this.tableData = list
      }
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }

      if (this.searchCondition.platform_code) {
        condition.platform_code = this.searchCondition.platform_code
      }

      if (this.searchCondition.time_value) {
        condition.start_time = this.searchCondition.time_value[0]
        condition.end_time = this.searchCondition.time_value[1]
      }

      return condition
    },

    defaultTimeRange() {
      this.radioChange()
    },
    initData() {
      this.getArtistTrendData()
    },
    async exportData() {
      try {
        let searchCondition = this.handleSearchCondition()
        let name = '红人【' + this.nickname + '】涨粉数据'
        let response = await this.$api.downloadTrendDatumArtistData(searchCondition)

        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    }
  },
  created() {
    this.searchCondition.artist_id = this.artistId
    this.defaultTimeRange()
    this.initData()
  }
}
</script>

<style scoped>
.title {
  font-size: 18px;
  font-weight: 800;
}
</style>
