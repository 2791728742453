<template>
  <div>
    <div>
      <el-form size='small' :inline='true'>
        <el-form-item label='年份选择：'>
          <span slot='label'><b>【总营收】年份：</b></span>
          <el-date-picker
              v-model='searchCondition.year'
              type='year'
              format='yyyy'
              value-format='yyyy'
              placeholder='选择年' clearable>
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model='checked'>对比去年</el-checkbox>
        </el-form-item>
        <el-form-item>
          <platforms-radio-group :show-all='true' size='mini' :platform-code='searchCondition.platform_code'
                                 @choicePlatform='choicePlatform'></platforms-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <echarts-chart :width='`100%`' :height='`500px`' :chart-option='chartOption' />
    </div>
  </div>
</template>

<script>
import PlatformsRadioGroup from '@/pages/platform/components/PlatformsRadioGroup'
import EchartsChart from '@/components/EchartsChart'

export default {
  name: 'RevenueBarChart',
  components: {
    PlatformsRadioGroup, EchartsChart
  },
  props: {
    artistId: {
      type: String
    }
  },
  watch: {
    artistId(val) {
      this.searchCondition.artist_id = val
    },
    searchCondition: {
      deep: true,
      handler() {
        this.getChartData()
      }
    },
    checked() {
      this.getChartData()
    }
  },
  data() {
    return {
      chartOption: {},
      searchCondition: {
        platform_code: null,
        artist_id: null,
        year: null
      },
      checked: false//是否对比去年
    }
  },
  methods: {
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.platform_code) {
        condition.platform_code = this.searchCondition.platform_code
      }
      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }

      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }
      return condition
    },

    async getChartData() {
      let chartOption = {
        color: ['#ff3176'],
        title: {
          text: '总营收情况一览',
          top: 0,
          left: '40%',
          textStyle: {
            color: '#303133',
            fontWeight: 'bold'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          orient: 'vertical',
          itemSize: '24',
          feature: {
            dataView: { show: true, readOnly: true },
            magicType: { show: true, type: ['bar', 'line'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        legend: {
          data: [],
          left: '60%',
          selected: {}//默认显示的echarts图
        },
        xAxis: [
          {
            type: 'category',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: { //轴文字标签
              show: true,
              color: '#ff3176'

            }

          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '金额（万元）',
            // min: 0,
            // max: 25,
            // interval: 5,
            axisLabel: {
              formatter: '{value}',
              show: true,
              color: '#ff3176'

            },
            nameTextStyle: {
              color: '#ff3176',
              padding: 10
            }
          },
          {
            type: 'value',
            name: '数量（单）',
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: '{value}',
              show: true,
              textStyle: {
                color: '#ff3176'
              }
            },
            nameTextStyle: {
              color: '#ff3176',
              padding: 10
            }
          }
        ],
        series: []
      }
      let searchCondition = this.handleSearchCondition()
      let barWidth = 0
      let barGap = '10%'
      // 修改柱状图 顶部数值 排版
      //https://echarts.apache.org/examples/zh/editor.html?c=bar-label-rotation
      let labelOption = {
        show: true,
        rotate: 90,
        align: 'left',
        verticalAlign: 'middle',
        position: 'inside',
        distance: 15,
        // formatter: '{c}  {name|{a}}',
        fontSize: 16,
        rich: {
          name: {}
        }
      }
      // 加载图表数据
      let chartData = await this.$api.getRevenueItemChartData(searchCondition)
      this.chartSeriesAppend(chartOption, chartData, searchCondition.year, barWidth, barGap, labelOption)
      if (this.checked) {
        searchCondition.year = searchCondition.year - 1
        let chartData = await this.$api.getRevenueItemChartData(searchCondition)
        this.chartSeriesAppend(chartOption, chartData, searchCondition.year, barWidth, barGap, labelOption)
      }
      this.initChartData(chartOption)
    },
    //图表-数据组装：追加
    chartSeriesAppend(chartOption, chartData, year, barWidth, barGap, labelOption) {

      this.appendBarChart(chartOption, `${year}-总营收`, 'bar', chartData.chat_data_revenue || [], true, barWidth, barGap, labelOption, 0)
      this.appendBarChart(chartOption, `${year}-订单数`, 'line', chartData.chat_data_order || [], true, barWidth, barGap, labelOption, 1)
      this.appendBarChart(chartOption, `${year}-直播收入`, 'bar', chartData.chat_data_live || [], false, barWidth, barGap, labelOption, 0)
      this.appendBarChart(chartOption, `${year}-视频佣金`, 'bar', chartData.chat_data_video || [], false, barWidth, barGap, labelOption, 0)
    },
    appendBarChart(chartOption, title, type, data, selected, barWidth, barGap, labelOption, yAxisIndex) {
      chartOption.legend.data.push(title)
      chartOption.legend.selected[title] = selected
      chartOption.series.push({
        name: title,
        type: type || 'bar',
        yAxisIndex: yAxisIndex,
        barWidth: barWidth,
        data: data,
        label: {
          show: true, //开启显示
          position: 'top', //在上方显示
          color: '#ff3176',
          fontSize: 12,
          labelOption
        },

        barGap: barGap
      })
    },

    initChartData(chartOption) {
      this.chartOption = chartOption
    },

    choicePlatform(val) {
      // console.log('searchCondition', this.searchCondition)
      this.searchCondition.platform_code = val
    },

    defaultTime() {
      //月份选择：默认本年度第一个月到本月
      const end = new Date()
      //年份选择：默认今年
      this.searchCondition.year = end.getFullYear() + ''
    }
  },
  created() {
    this.searchCondition.artist_id = this.artistId
    this.defaultTime()
  },
  mounted() {
    this.getChartData()
  }
}
</script>

<style scoped>
#revenue_chart {
  /*border: #0000BB dashed 1px;*/
  height: 600px;
  width: 80%;
  padding: 20px;
  color: #9f00bb;
}
</style>
