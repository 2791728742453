<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <el-row>
        <el-col :span="24">
          <span class="title">刊例报价</span>
        </el-col>
      </el-row>
    </div>

    <el-form :inline="true">
      <el-form-item label="年份选择：">
        <span slot="label"><b>年份：</b></span>
        <el-date-picker
            v-model="searchCondition.year"
            type="year"
            format="yyyy"
            value-format="yyyy"
            placeholder="选择年" @change="getQuotationData" clearable>
        </el-date-picker>
      </el-form-item>

      <!--                平台选择-->
      <el-form-item>
        <PlatformsRadioGroup :platformCode="searchCondition.platform_code" :purpose="`is_plc_entry`"
                             @choicePlatform="choicePlatform"></PlatformsRadioGroup>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-refresh" type="default" @click="init"></el-button>
      </el-form-item>

    </el-form>

    <!--        表格-->
    <el-row :gutter="20">
      <el-col :span="24">
        <div class="default-table">
          <el-table :data="tableData" style="width: 100%" height="500px" border>
            <el-table-column prop="month" label="月份" width="120" align="center">
              <template slot-scope="scope">
                {{ scope.row.year }}年{{ scope.row.month }}月
              </template>
            </el-table-column>
            <el-table-column prop="real_quote" label="商业化营销中心终价" min-width="280" header-align="center"
                             align="left">
              <template slot-scope="{row,column}">
                <div class="price-div">
                  <div class="edit-box">
                    <PriceGrids :rows="row[column.property]"/>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <el-divider content-position="left">近12个月商业化营销中心终价曲线图</el-divider>
    <el-row :gutter="20">
      <el-col :span="24">
        <echarts-chart :width="`100%`" :chart-option="chartOption"/>
      </el-col>
    </el-row>

  </el-card>
</template>

<script>

import EchartsChart from "@/components/EchartsChart";
import PriceGrids from '@/pages/publication/PriceGrids'
import PlatformsRadioGroup from "@/pages/platform/components/PlatformsRadioGroup"

export default {
  name: 'Quotation',
  props: {
    artistId: {
      type: String,
      require: true
    }
  },
  watch: {
    artistId(val) {
      this.searchCondition.artist_id = val
      this.getQuotationData()
    }
  },
  components: {PriceGrids, PlatformsRadioGroup, EchartsChart},
  data() {
    return {
      searchCondition: {
        year: '2022',
        artist_id: 'artzf1bkppmkcggr54ux2304a7a89016',
        platform_code: 'douyin'
      },
      loadingStatus: false,
      tableData: [
        // {
        //   "year": 2022,
        //   "month": 8,
        //   "real_quote": {
        //     "multi": [],
        //     "single": [
        //       {
        //         "val": "20000",
        //         "field": "quote_1",
        //         "title": "原创视频\r\n1-20s",
        //         "remark": "拼广1-2个品"
        //       },
        //       {
        //         "val": "30000",
        //         "field": "quote_2",
        //         "title": "原创视频\n21-60s",
        //         "remark": "送快手分发"
        //       },
        //       {
        //         "val": "50000",
        //         "field": "quote_3",
        //         "title": "原创视频\r\n60s+",
        //         "remark": ""
        //       },
        //       {
        //         "val": "3000",
        //         "field": "quote_4",
        //         "title": "原创图文\r\n（4-6张）",
        //         "remark": ""
        //       },
        //       {
        //         "val": "3000",
        //         "field": "quote_5",
        //         "title": "随手PO视频\r\n5s",
        //         "remark": "1.抖音+小红书打包200000。2.送1条24小时日常+快手分发"
        //       }
        //     ]
        //   }
        // },
        // {
        //   "year": 2022,
        //   "month": 8,
        //   "real_quote": {
        //     "multi": [],
        //     "single": [
        //       {
        //         "val": "20000",
        //         "field": "quote_1",
        //         "title": "原创视频\r\n1-20s",
        //         "remark": "拼广1-2个品"
        //       },
        //       {
        //         "val": "30000",
        //         "field": "quote_2",
        //         "title": "原创视频\n21-60s",
        //         "remark": "送快手分发"
        //       },
        //       {
        //         "val": "50000",
        //         "field": "quote_3",
        //         "title": "原创视频\r\n60s+",
        //         "remark": ""
        //       },
        //       {
        //         "val": "3000",
        //         "field": "quote_4",
        //         "title": "原创图文\r\n（4-6张）",
        //         "remark": ""
        //       },
        //       {
        //         "val": "3000",
        //         "field": "quote_5",
        //         "title": "随手PO视频\r\n5s",
        //         "remark": "1.抖音+小红书打包200000。2.送1条24小时日常+快手分发"
        //       }
        //     ]
        //   }
        // }
      ],
      chartData: {},
      chartOption: {},
    }
  },
  methods: {
    init() {
      this.getQuotationData()
      this.getChartsData()
    },
    async getChartsData() {
      let {charts_data} = await this.$api.getQuotationCharts(this.searchCondition)
      // console.log(charts_data)
      this.$nextTick(() => {
        this.chartData = charts_data
        let xAxisData = charts_data.xAxisData || []

        let chartOption = {
          color: ['#ff3176'],
          title: {
            text: '刊例报价一览',
            top: 0,
            left: '40%',
            textStyle: {
              color: '#303133',
              fontWeight: 'bold',
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          toolbox: {
            orient: 'vertical',
            itemSize: '24',
            feature: {
              // dataView: {show: true, readOnly: true},
              magicType: {show: true, type: ['bar', 'line',]},
              restore: {show: true},
              saveAsImage: {show: true}
            }
          },
          legend: {
            data: [],
            left: '60%'
          },
          xAxis: [
            {
              type: 'category',
              data: xAxisData || [],
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: { //轴文字标签
                show: true,
                textStyle: {
                  color: '#ff3176',
                }
              },

            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '定价（元）',
              // min: 0,
              // max: 25,
              // interval: 5,
              axisLabel: {
                formatter: '{value}',
                show: true,
                textStyle: {
                  color: '#ff3176',
                }
              },
              nameTextStyle: {
                color: '#ff3176',
                padding: 10
              },
            },
          ],
          series: []
        }
        let lineRows = charts_data.seriesDataArr || []
        lineRows.forEach((item) => {
          chartOption.legend.data.push(item.title)
          let seryData = {
            name: item.title,
            type: 'line',
            yAxisIndex: 0,
            data: item.data
          }
          chartOption.series.push(seryData)
        })

        this.chartOption = chartOption
      })

    },
    async getQuotationData() {
      //获取刊例报价
      this.tableData = await this.$api.getQuotationData(this.searchCondition)
    },

    choicePlatform(val) {
      this.searchCondition.platform_code = val;
      this.init()
    }

  },
  created() {
    this.searchCondition.artist_id = this.artistId
    this.init()
  }

}
</script>

<style scoped>
.title {
  font-size: 18px;
  font-weight: 800;
}
</style>