<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title">电商数据</span>
      </div>
      <div>
        <div class="table-search">
          <el-form ref="searchForm" :inline="true" :model="searchCondition">
            <el-form-item>
              <el-radio-group v-model="checkedRadio" size="small" @change="radioChange">
                <el-radio-button v-for="(option,index) in dayRange" :key="index" :label="option.value">
                  {{ option.label }}
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item>
              <el-date-picker
                  v-model="ym"
                  size="small"
                  type="month"
                  value-format="yyyy-MM"
                  placeholder="选择月份" style="width: 120px" @change="changeMonth">
              </el-date-picker>
            </el-form-item>
            <el-form-item >
              <el-date-picker
                  v-model="searchCondition.time_value"
                  type="daterange"
                  size="small" unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd" @change="changeTime">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <platforms-radio-group :platform-code='searchCondition.platform_code' :show-all='true'
                                       @choicePlatform='choicePlatform'></platforms-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <LiveDataTable :search-condition="searchCondition"/>
        <el-divider></el-divider>
        <el-row>
          <el-col :span='24'>
            <BsChart :artist-id='artistId' />
          </el-col>
        </el-row>
      </div>
    </el-card>

  </div>
</template>

<script>
import LiveDataTable from '@/pages/live_data/LiveDataTable'
import BsChart from '@/pages/dashboard/revenue/BsChart.vue'
import PlatformsRadioGroup from '@/pages/platform/components/PlatformsRadioGroup'

export default {
  name: "BsData",
  components: {
    LiveDataTable,BsChart,PlatformsRadioGroup
  },
  props: {
    artistId: {
      type: String,
      require: true
    }
  },
  watch: {
    artistId() {
      // this.getInfo()
    }
  },
  data() {
    return {
      dayRange: [
        { label: '近30天', value: 30 },
        { label: '近60天', value: 60 },
        { label: '近90天', value: 90 },
      ],
      checkedRadio: null,
      searchCondition: {
        time_value: [],
        artist_id: null
      },
      ym: null
    }
  },
  methods: {
    defaultDate() {
      this.checkedRadio = 30
      // 默认选择
      this.choiceTimeData(30)
    },
    getLastDays(days) {
      //几天前的时间
      let day1 = new Date();
      day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000 * days);
      return day1.getFullYear() + "-" + (day1.getMonth() + 1) + "-" + day1.getDate();
    },
    choiceTimeData(days) {
      //选择时间
      let beginTime = this.getLastDays(days)
      let endTime = this.getLastDays(1)
      this.searchCondition.time_value = [beginTime, endTime];
    },
    radioChange() {
      this.choiceTimeData(this.checkedRadio)
    },
    changeTime() {
      this.ym = null
      this.checkedRadio = null
    },
    choicePlatform(val) {
      // console.log('searchCondition', this.searchCondition)
      this.searchCondition.platform_code = val
      this.radioChange()
    },

    changeMonth() {
      this.checkedRadio = null
      if (this.ym) {
        let ymArr = this.ym.split('-')
        let day = new Date(parseInt(ymArr[0]), parseInt(ymArr[1]), 0);
        let daycount = day.getDate();//最后一天

        this.searchCondition.time_value = [this.ym + '-01', this.ym + '-' + daycount]
      } else {
        this.searchCondition.time_value = []
      }
    },
  },
  created() {
    this.searchCondition.artist_id = this.artistId
  },
  mounted() {
    this.defaultDate()
  }
}
</script>

<style scoped>
.title {
  font-size: 18px;
  font-weight: 800;
}

</style>
