<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-row>
          <el-col :span="24">
            <span class="title">抖音挂车（视频）案例</span>
          </el-col>
        </el-row>
      </div>
      <div>
        <el-form :inline="true">
          <el-form-item label="年份选择：">
            <span slot="label"><b>年份：</b></span>
            <el-date-picker
                v-model="searchCondition.year"
                type="year"
                format="yyyy"
                value-format="yyyy"
                placeholder="选择年" @change="initList" clearable>
            </el-date-picker>
          </el-form-item>
<!--          <el-form-item>-->
<!--            <el-link class="el-icon-refresh" @click="initList">刷新</el-link>-->
<!--          </el-form-item>-->
        </el-form>
        <div class="default-table">
          <ApeTable ref="apeTable"
                    :data="dataList"
                    :columns="columns"
                    :loading="loadingStatus"
                    :pagingData="pagingData"
                    :summary-method="getSummaries"
                    @changeTableSort="changeTableSort"
                    show-summary
                    highlight-current-row
                    border>
            <el-table-column prop="group_id" label="组别" min-width="100" align="center" show-overflow-tooltip
                             slot="first-column">
              <template slot-scope="scope">
                <span>{{ scope.row.group_name }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="nickname" label="红人昵称" min-width="120" align="center" show-overflow-tooltip
                             slot="first-column" sortable="custom">
              <template slot-scope="scope">
                <artist-nickname :info="scope.row"/>
              </template>
            </el-table-column>
            <el-table-column prop="product_name" label="带货产品名" min-width="200" header-align="center" align="center"
                             show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.product_name }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="launch_date" label="上线日期" min-width="100" header-align="center" align="center"
                             sortable="custom" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.launch_date }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="price" label="售价" min-width="120" header-align="center" align="right"
                             sortable="custom"
                             show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ moneyFormat(scope.row.price) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="sales_qty" label="销售量" min-width="120" header-align="center" align="right"
                             sortable="custom"
                             show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ numFormat(scope.row.sales_qty) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="month_amount" label="截至本月销售额" min-width="120" header-align="center" align="right"
                             sortable="custom" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ moneyFormat(scope.row.month_amount) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="end_date" label="统计截止时间" min-width="100"
                             header-align="center" align="center"
                             sortable="custom" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.end_date }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="created_at" label="录入时间" min-width="100" header-align="center" align="center"
                             sortable="custom" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.created_at }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="source_link" label="视频内容" min-width="80" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-link type="primary" :href="scope.row.source_link" target="_blank">
                  下载
                  <i class="el-icon-download el-icon--right"></i>
                </el-link>
              </template>
            </el-table-column>
            <!--        <el-table-column prop="created_at" label="创建时间" min-width="100" align="center" show-overflow-tooltip></el-table-column>-->
            <!--        <el-table-column prop="updated_at" label="修改时间" min-width="100" align="center" show-overflow-tooltip></el-table-column>-->
          </ApeTable>

        </div>

      </div>
    </el-card>
  </div>
</template>

<script>
import ApeTable from "@/components/ApeTable"

export default {
  name: "TiktokData",
  components: {
    ApeTable,
  },
  props: {
    artistId: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      searchCondition: {
        year: null,
        artist_id: null
      },
      loadingStatus: false,
      dataList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 200
      },
      columns: [],
      orderSort: { launch_date: 'desc' },//默认排序规则
    }
  },
  methods: {
    rateFormat(value) { // 金额 格式化
      return this.$utils.numberFormat(value, 2, '.', ',', 'round')
    },
    moneyFormat(value) { // 金额 格式化
      return this.$utils.numberFormat(value, 2, '.', ',', 'round')
    },
    numFormat(value) { // 数值 格式化
      return this.$utils.numberFormat(value, 0, '.', ',', 'round')
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '当前页合计：';
          return;
        }
        if (['month_amount', 'sales_qty'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += ' ';
          } else {
            sums[index] = 'N/A';
          }

          switch (column.property) {
            case 'sales_qty':
              sums[index] = this.numFormat(sums[index])
              break
            case 'month_amount':
              sums[index] = '¥' + this.moneyFormat(sums[index])
              break
            default:
              // sums[index] = this.valFormat(sums[index])
              break
          }
        }

      });

      return sums;
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.initList()
    },
    defaultTime() {
      let day1 = new Date();
      this.searchCondition.year = day1.getFullYear() + ''
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }

      if (this.searchCondition.year) {
        let year = this.searchCondition.year
        let day = new Date(parseInt(year), parseInt(12), 0);
        let daycount = day.getDate();//最后一天
        condition.start_time = year + '-01-01'
        condition.end_time = year + '-12-' + daycount
      }
      return condition
    },

    async initList(type) {
      this.loadingStatus = true
      let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, pagingInfo, { order: this.orderSort })
      this.dataList = []
      let { list, pages } = await this.$api.getCaseRecordList(searchCondition)
      this.$nextTick(() => {
        this.dataList = list
      })
      this.pagingData.total = pages.total
      // this.offset = pages.offset
      this.loadingStatus = false
    },
  },
  created() {
    this.searchCondition.artist_id = this.artistId
    this.defaultTime()

  },
  mounted() {
    this.initList()
  }
}
</script>

<style scoped>
.title {
  font-size: 18px;
  font-weight: 800;
}
</style>
