<template>
  <div>
    <el-card class='box-card'>
      <div slot='header' class='clearfix'>
        <span class='title'>红人基本信息</span>
      </div>
      <div>
        <el-form label-position='left' inline class='demo-table-expand'>
          <el-form-item label='红人昵称'>
            <span>{{ info.nickname || '--' }}</span>
          </el-form-item>
          <el-form-item label='签约状态'>
            <el-tag v-if="info.sign_status === 'Y'" effect='plain' type='success'>在约</el-tag>
            <el-tag v-else effect='plain' type='info'>解约</el-tag>
          </el-form-item>
          <el-form-item label='合作时长'>
            <span>{{ info.duration ? info.duration : '--' }} 月</span>
          </el-form-item>
          <el-form-item label='签约时间'>
            <span>
              {{ $utils.parseTime(info.sign_start, '{y}/{m}/{d}') }}
              ~
              {{ $utils.parseTime(info.sign_end, '{y}/{m}/{d}') }}</span>
          </el-form-item>
          <el-form-item label='解约时间' v-if="info.sign_status==='N'">
            <span>{{ $utils.parseTime(info.fired_at, '{y}/{m}/{d}') }}</span>
          </el-form-item>
          <el-form-item label='组别'>
            <span>{{ info.dept ? info.dept.name : '--' }}</span>
          </el-form-item>
          <!--          <el-form-item label="备注信息">-->
          <!--            <span>{{ info.remark || '&#45;&#45;' }}</span>-->
          <!--          </el-form-item>-->
          <el-form-item label='录入时间'>
            <span>{{ info.created_at }}(更新时间：{{ info.updated_at }})</span>
          </el-form-item>
          <el-form-item label='平台信息'>
            <PlatformLogos :platforms='platforms' :kol-platforms='info.platforms'></PlatformLogos>
<!--            <div v-for='(platform,key ) in platforms' :key='key' style='display: inline'>-->
<!--              <el-image-->
<!--                style='width: 30px; height: 30px; padding: 5px;'-->
<!--                :class="`${showPlatform(platform.code)?'platform-yes':'platform-no'}`"-->
<!--                :src="`${platform.logo_url?platform.logo_url:'https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png'}`"-->
<!--                fit='contain'>-->
<!--              </el-image>-->
<!--            </div>-->
          </el-form-item>
          <el-form-item>
            <el-link type='primary' @click='showLog(info)'>
              <svg-icon :icon-class="showLogView?'eye':'eye-open'"></svg-icon>
              红人日志
            </el-link>
          </el-form-item>
        </el-form>
      </div>
      <div v-if='showLogView'>
        <ArtistLog :artist-id='info.id' />
      </div>
    </el-card>

  </div>
</template>

<script>
import ArtistLog from '@/pages/artist/ArtistLog'
import PlatformLogos from '@/components/Platform/PlatformLogos'

export default {
  name: 'ArtistInfo',
  components: { PlatformLogos, ArtistLog },
  data() {
    return {
      searchCondition: { artist_id: null },
      info: {},
      platforms: [],
      purpose: 'is_data_entry',
      showLogView: false
    }
  },
  props: {
    artistId: {
      type: String,
      require: true
    }
  },
  watch: {
    artistId(val) {
      this.searchCondition.artist_id = val
      this.getInfo()
    }
  },
  methods: {
    showLog() {
      this.showLogView = !this.showLogView
    },
    async getInfo() {
      let { info } = await this.$api.getArtistInfo(this.artistId)
      this.info = info
    },
    async getPlatforms() {
      //从缓存中读取上次的平台信息
      let platformsJson = localStorage.getItem('platforms')
      if (platformsJson) {
        this.platforms = JSON.parse(platformsJson)
      }
      // 获取最新平台信息
      let searchCondition = this.handleSearchCondition()
      let { list } = await this.$api.getPlatformAll(searchCondition)
      this.platforms = list
      // 缓存平台信息
      localStorage.setItem('platforms', JSON.stringify(list))
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      condition.status = 1

      if (this.purpose === 'is_data_entry') {
        condition.is_data_entry = 1
      }

      if (this.purpose === 'is_plc_entry') {
        condition.is_plc_entry = 1
      }
      return condition
    },
    showPlatform(code) {
      let isShow = false
      let myPlatforms = this.info.platforms
      if (myPlatforms && code) {
        isShow = myPlatforms[code] === 'Y'
      }

      return isShow
    }
  },
  created() {
    this.searchCondition.artist_id = this.artistId
    this.getPlatforms()
    this.getInfo()
  }
}
</script>

<style scoped>
.title {
  font-size: 18px;
  font-weight: 800;
}

.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

/**
opacity 在IE8及以下是不兼容的；所以要设置filter属性
支持firefox : -moz-opacity:0.3;
 */
.platform-no {
  opacity: 0.3;
  -moz-opacity: 0.3;
  filter: alpha(opacity=30);
}
</style>
