<template>
  <div>
      <div class="main-page-content">

        <div class="view" v-for="item in componentList"
             :id="item.name" :key="item.Permissions" style="min-height: 100px">
          <!--        <keep-alive>-->
          <!--            {{showData.includes(item.name)}}-->
          <component v-if="userPermissions.indexOf(item.Permissions) !== -1&&item.isShow"
                     v-bind:is="item.name"
                     :id="item.name" :artist-id="artist_id"></component>
          <!--        </keep-alive>-->
        </div>
<!--        <div class='view' v-if="userPermissions.indexOf('artist_detail') !== -1" id='artist_detail'>-->
<!--          <ArtistInfo :artist-id='artist_id' />-->
<!--        </div>-->
<!--        <div class='view' v-if="userPermissions.indexOf('artist_trend_data') !== -1" ref='artist_trend_data'>-->
<!--          <TrendData :artist-id='artist_id' :nickname='info.nickname' />-->
<!--        </div>-->
<!--        <div class='view' v-if="userPermissions.indexOf('artist_revenue_data') > -1">-->
<!--          <RevenueData :artist-id='artist_id' />-->
<!--        </div>-->
<!--        <div class='view' v-if="userPermissions.indexOf('artist_bs_data') > -1">-->
<!--          <BsData :artist-id='artist_id'/>-->
<!--        </div>-->
<!--        <div class='view' v-if="userPermissions.indexOf('artist_case_record') > -1">-->
<!--          <TiktokData :artist-id='artist_id'/>-->
<!--        </div>-->
<!--        <div class='view' v-if="userPermissions.indexOf('artist_intro_plc') > -1">-->
<!--          <Quotation :artist-id='artist_id' ></Quotation>-->
<!--        </div>-->
      </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ArtistInfo from './ArtistInfo'
import TrendData from './TrendData'
import RevenueData from './RevenueData'
import BsData from './BsData'
import TiktokData from './TiktokData'
import Quotation from './Quotation'

export default {
  name: 'ArtistIntro',
  computed: {
    ...mapGetters(['userPermissions'])
  },
  components: {
    ArtistInfo, TrendData, RevenueData, BsData, TiktokData, Quotation
  },
  data() {
    return {
      artist_id: null,
      info: {},
      showId: '',
      observer: null,
      componentList: [{
        name: 'ArtistInfo',
        Permissions: 'artist_detail',
        isShow: true
      }, {
        name: 'TrendData',
        Permissions: 'artist_trend_data',
        isShow: true
      },
        {
          name: 'RevenueData',
          Permissions: 'artist_revenue_data',
          isShow: false
        }, {
          name: 'BsData',
          Permissions: 'artist_bs_data',
          isShow: false

        }, {
          name: 'TiktokData',
          Permissions: 'artist_case_record',
          isShow: false
        }, {
          name: 'Quotation',
          Permissions: 'artist_intro_plc',
          isShow: false
        }
      ]
    }
  },
  methods: {
    async getInfo() {
      let { info } = await this.$api.getArtistInfo(this.artist_id)
      this.info = info
    },


    sendAsk() {
      let lis = document.querySelectorAll('.view')
  //swHeight=滚动的高度+窗体的高度；当li的offset高度<=swHeight,那么说明当前li显示在可视区域了
      let swHeight = window.screen.height
      lis.forEach((item) => {
        let mTop = item.getBoundingClientRect().top + 75
        let mHeight = item.clientHeight
        if (mTop - Number(-mHeight) > 75 && mTop < swHeight) {
          let index = this.componentList.findIndex((data) => {
            return data.name === item.id
          })
          this.componentList[index + 1].isShow = true
        }
      })
    }
  }
  ,
  created() {
    this.artist_id = this.$route.params.artist_id
    this.getInfo()
  }
  ,
  mounted() {
    let _t = this
    window.addEventListener('scroll', function() {
      _t.sendAsk()
    })
  },
  beforeDestroy() {
  }
}
</script>

<style scoped>
.view {
  margin-bottom: 10px;
}

.intro {
  height: 100vh;
  min-height: 100vh;
}

</style>
